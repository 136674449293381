import { createSettingsClient, AppSettings } from '@wix/search-settings-client';
import { ExperimentsBag } from '@wix/yoshi-flow-editor';

import { EditorAppContext } from './types';

export type AppApi = {
  getSavedSettings(options: {
    instance: string;
    apiBaseUrl?: string;
  }): Promise<AppSettings>;
  saveSettings<T extends keyof AppSettings>(
    options: { instance: string; apiBaseUrl?: string },
    key: T,
    value: AppSettings[T],
  ): Promise<void>;
  getExperiments(): ExperimentsBag;
};

export const createAppApi = (appContext: EditorAppContext): AppApi => {
  return {
    getSavedSettings({ instance, apiBaseUrl }) {
      const settingsClient = createSettingsClient({
        instance,
        apiBaseUrl,
        reportError: appContext.handleException,
      });
      return settingsClient.getSaved();
    },
    saveSettings({ instance, apiBaseUrl }, key, value) {
      const settingsClient = createSettingsClient({
        instance,
        apiBaseUrl,
        reportError: appContext.handleException,
      });
      return settingsClient.set(key, value);
    },
    getExperiments() {
      return appContext.experiments.all();
    },
  };
};
