import { ComponentRef } from '@wix/platform-editor-sdk';

import { getSearchAppControllers } from '../searchAppController';
import { isSearchBox } from '../searchBox';
import { EditorAppContext } from '../types';
import { getControllerConnectedComponents } from '../editorSDKHelpers';

export async function getConnectedSearchBoxes(appContext: EditorAppContext) {
  const controllerRefs = await getSearchAppControllers(appContext);
  const connectedSearchBoxes: ComponentRef[] = [];

  for (const controllerRef of controllerRefs) {
    const connectedComponentsRefs = await getControllerConnectedComponents(
      appContext,
      controllerRef,
    );

    for (const componentRef of connectedComponentsRefs) {
      if (await isSearchBox(appContext, componentRef)) {
        connectedSearchBoxes.push(componentRef);
      }
    }
  }
  return connectedSearchBoxes;
}
