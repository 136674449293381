import { ComponentRef } from '@wix/platform-editor-sdk';
import {
  SearchDocumentType,
  ISuggestionsResponse,
  SuggestionsParser,
  getMockedSearchResults,
} from '@wix/client-search-sdk';
import { EditorAppContext } from './types';

export async function setDemoSuggestions(
  appContext: EditorAppContext,
  componentRef: ComponentRef,
) {
  /**
   * NOTE: add base url so suggestion will work in editor
   */
  // TODO pass through more props to have baseUrl here. Also language?
  const parser = new SuggestionsParser('', 'en');

  const results = [
    ...getMockedSearchResults({
      query: '',
      documentType: SearchDocumentType.Pages,
      limit: 3,
      skip: 0,
    }),
    ...getMockedSearchResults({
      query: '',
      documentType: SearchDocumentType.Products,
      limit: 3,
      skip: 0,
    }),
  ];

  const documents: ISuggestionsResponse['suggestions'] = parser.parse(results);
  const { appDefinitionId, editorSDK, translate } = appContext;
  const suggestions = {
    query: translate('searchResults.searchBox.input.placeHolderText'),
    searchAllUrl: '/search-term',
    documents: documents.map((document) => {
      return {
        ...document,
        url: '/document-type',
      };
    }),
  };
  return editorSDK.components.data.update(appDefinitionId, {
    componentRef,
    data: {
      suggestions,
    },
  });
}
