import { ComponentRef } from '@wix/platform-editor-sdk';
import { EditorAppContext } from '../types';

// TODO sounds like a hacky way to achieve something
export async function patchInputFontProperty(
  appContext: EditorAppContext,
  componentRef: ComponentRef,
) {
  // patch component style to fix SCALE_UP/SCALE_DOWN GFPP mobile actions. see also:
  //    https://jira.wixpress.com/browse/SER-403
  //    https://github.com/wix-private/site-search/pull/363
  const { editorSDK, appDefinitionId } = appContext;
  const old = await editorSDK.components.style.get(appDefinitionId, {
    componentRef,
  });
  const needsInputFont =
    old && old.style && old.style.properties && !old.style.properties.inputFont;

  if (!needsInputFont) {
    return;
  }

  await editorSDK.components.style.updateFull(appDefinitionId, {
    componentRef,
    style: {
      ...old,
      style: {
        ...old.style,
        properties: {
          ...old.style.properties,
          inputFont: 'font_8',
        },
        propertiesSource: {
          ...old.style.propertiesSource,
          inputFont: 'value',
        },
      },
    },
  });
}
