export enum Interaction {
  EditorReady = 'EditorReady',
  InstallApp = 'InstallApp',
  RemoveApp = 'RemoveApp',
  SearchBoxAdded = 'SearchBoxAdded',
}

export enum FedopsInteraction {
  EditorReady = 'EditorReady',
  InstallApp = 'InstallApp',
  RemoveApp = 'RemoveApp',
  SearchBoxAdded = 'SearchBoxAdded',
}
