import { getSearchAppControllers } from '../searchAppController';
import { EditorAppContext } from '../types';
import { Interaction } from '../constants/interaction';
import {
  removeController,
  removeControllerConnectedComponents,
} from '../editorSDKHelpers';

export async function onRemoveApp(appContext: EditorAppContext) {
  const { fedops, sentry } = appContext;
  fedops.interactionStarted(Interaction.RemoveApp);

  const controllerRefs = await getSearchAppControllers(appContext);

  for (const controllerRef of controllerRefs) {
    try {
      await removeControllerConnectedComponents(appContext, controllerRef);
    } catch (ex) {
      sentry.captureException(ex);
    }

    try {
      await removeController(appContext, controllerRef);
    } catch (ex) {
      sentry.captureException(ex);
    }
  }

  await saveEditor(appContext);
  fedops.interactionEnded(Interaction.RemoveApp);
}

async function saveEditor(appContext: EditorAppContext) {
  const { editorSDK, sentry, appDefinitionId } = appContext;

  try {
    await editorSDK.editor.save(appDefinitionId);
  } catch (ex) {
    sentry.captureException(ex);
  }
}
